<template>
  <div>
    <notifications group="notification" position="top right" />
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title">
          <span class="card-label font-weight-bolder text-dark" style="display: block;">
            {{ $t('PROMO_BOX.PROMO_BOXES') }}
          </span>
        </h3>
        <div class="card-toolbar">
          <router-link
            to="/loyalty/promo-boxes/new"
            class="btn btn-primary font-weight-bolder"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg src="media/svg/icons/Shopping/Credit-card.svg" /> 
            </span>
            {{ $t('PROMO_BOX.ADD_NEW') }}
          </router-link>
        </div>
      </div>
      <div class="card-body py-0">
        <div class="table-responsive">
          <table class="table table-head-custom table-vertical-center table-head-bg">
            <thead>
              <tr class="text-left text-uppercase">
                <th class="px-6" style="max-width: 700px; display: block;">
                  <span @click="orderByTitle" class="cursor-pointer">
                    <span class="pt-2" :class="{ 'text-primary': titleOrder.isActive }">
                      {{ $t('PROMO_BOX.TITLE') }}
                    </span>
                    <span class="svg-icon svg-icon-sm pl-2" :class="{ 'svg-icon-primary': titleOrder.isActive }">
                      <inline-svg v-if="titleOrder.query === 'asc'" src="media/svg/icons/Navigation/Down-2.svg" />
                      <inline-svg v-else src="media/svg/icons/Navigation/Up-2.svg" />
                    </span>
                  </span>
                </th>
                <th>
                  <span @click="orderByBackgroundColor" class="cursor-pointer">
                    <span class="pt-2" :class="{ 'text-primary': backgroundColorOrder.isActive }">
                      {{ $t('PROMO_BOX.BACKGROUND_COLOR') }}
                    </span>
                    <span class="svg-icon svg-icon-sm pl-2" :class="{ 'svg-icon-primary': backgroundColorOrder.isActive }">
                      <inline-svg v-if="backgroundColorOrder.query === 'asc'" src="media/svg/icons/Navigation/Down-2.svg" />
                      <inline-svg v-else src="media/svg/icons/Navigation/Up-2.svg" />
                    </span>
                  </span>
                </th>
                <th>{{ $t('PROMO_BOX.TEXT_COLOR') }}</th>
                <th>{{ $t('PROMO_BOX.ACTION_BUTTON') }}</th>
                <th class="text-right pr-6">{{ $t('PROMO_BOX.OPTIONS') }}</th>
              </tr>
            </thead>
            <tbody v-if="isPromoBoxesLoaded">
              <tr v-for="(promoBox, key) in promoBoxes" :key="key">
                <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                  <router-link
                    :to="'/loyalty/promo-boxes/edit/' + promoBox.id"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    style="max-width: 700px; display: block;"
                  >{{ promoBox.title }}</router-link>
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <span>{{ promoBox.backgroundColor }}</span>
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <span>{{ promoBox.textColor }}</span>
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <span>{{ promoBox.actionButtonType }}</span>
                </td>
                <td class="text-right" :class="{ 'border-top-0': key === 0 }">
                  <div style="display: flex; align-items: center; justify-content: center;">
                    <button @click="editPromoBox(promoBox.id)" class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2">
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/General/Settings-1.svg" />
                    </span>
                  </button>
                  <button @click="deletePromoBox(promoBox.id)" class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon">
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                    </span>
                  </button>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5" style="width: 100%; text-align: center">
                  <b-spinner class="mt-5" variant="primary" label="Spinning"></b-spinner>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import axios from 'axios'
import ApiService from '@/core/services/api.service'
import Vue from 'vue'

export default {
  name: 'PromoBoxList',
  data() {
    return {
      promoBoxes: [],
      isPromoBoxesLoaded: false,
      titleOrder: { query: 'desc', isActive: false },
      backgroundColorOrder: { query: 'desc', isActive: false },
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Loyalty Promo Boxes', route: '' },
    ])

    axios
      .get(`${process.env.VUE_APP_LOYALTY_API_ENDPOINT_ROUTE_API_URL}/api/v1/promo_boxes`)
      .then(({ data }) => {
        this.promoBoxes = data['hydra:member']
        this.isPromoBoxesLoaded = true
      })
  },
  methods: {
    orderByTitle() {
      this.titleOrder.isActive = true
      this.titleOrder.query = this.titleOrder.query === 'asc' ? 'desc' : 'asc'

      if (this.titleOrder.query === 'asc') {
        this.promoBoxes.sort((a, b) => a.title.localeCompare(b.title))
      } else {
        this.promoBoxes.sort((a, b) => b.title.localeCompare(a.title))
      }
    },
    orderByBackgroundColor() {
      this.backgroundColorOrder.isActive = true
      this.backgroundColorOrder.query = this.backgroundColorOrder.query === 'asc' ? 'desc' : 'asc'

      if (this.backgroundColorOrder.query === 'asc') {
        this.promoBoxes.sort((a, b) => a.backgroundColor.localeCompare(b.backgroundColor))
      } else {
        this.promoBoxes.sort((a, b) => b.backgroundColor.localeCompare(a.backgroundColor))
      }
    },
    editPromoBox(id) {
      this.$router.push({ name: 'loyalty-promo-boxes-edit', params: { id } })
    },
    deletePromoBox(id) {
      ApiService.delete(`${process.env.VUE_APP_LOYALTY_API_ENDPOINT_ROUTE_API_URL}/api/v1/promo_boxes/${id}`).then(() => {
        this.promoBoxes = this.promoBoxes.filter(box => box.id !== id)

        Vue.notify({
          group: 'notification',
          type: 'success',
          title: 'Deleted',
          text: 'Promo Box has been deleted successfully.'
        })
      }).catch(error => {
        Vue.notify({
          group: 'notification',
          type: 'error',
          title: `${error}`,
          text: 'An error occurred while trying to delete the Promo Box.'
        })
      })
    },
  },
}
</script>
